import { lazy } from "react";
import type { LottieProps } from "react-lottie-player";
import { ClientOnly } from "../ClientOnly";

// eslint-disable-next-line @typescript-eslint/promise-function-async
const LazyLoadedLottie = lazy(() => {
  return import("react-lottie-player");
});

/**
 * HACK: SSR環境だとRemixのデプロイに失敗するのでClientだけで読み込む
 *
 * https://github.com/remix-run/remix/discussions/2936
 */
export function ClientLottie(
  props: LottieProps & {
    readonly fallback?: React.ReactNode;
  },
): JSX.Element {
  return (
    <ClientOnly fallback={props.fallback}>
      <LazyLoadedLottie {...props} />
    </ClientOnly>
  );
}
